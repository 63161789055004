import React from 'react';
import Eheader from '../Components/events/Eheader';
import Events from '../Components/events/Events';
import Calendar from '../Components/calendar/Calendar';

export default function Homefull() {
  return (
    <div>
      <Eheader />
      <Events />
      <Calendar />
    </div>
  );
}