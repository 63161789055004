import React from "react";
import { Container, Flex, Image } from "@chakra-ui/react";
import VideoPlaceholder from "../../images/vidplaceholder.png";
import "react-calendar/dist/Calendar.css";

const Events = () => {
  return (
    <Container
      maxW="none"
      textAlign="center"
      p={0}
      m={0}
      style={{ fontFamily: "Avenir, sans-serif" }}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="center"
        py={6}
        mt={0}
        mb={5}
      >
        <Image
          src={VideoPlaceholder}
          alt="Video Placeholder"
          boxSize={{ base: "70%", md: "40%" }}
        />
      </Flex>
    </Container>
  );
};

export default Events;
