import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Link,
  IconButton,
  SimpleGrid,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { getAllBlogPosts } from "../../service/blog";
import Articlecover from "../../images/articlecover.png";
import "../../styles/fonts.css";

const ArticlesSection = () => {
  const [latestArticles, setLatestArticles] = useState([]);

  useEffect(() => {
    const fetchLatestArticles = async () => {
      try {
        const response = await getAllBlogPosts();
        setLatestArticles((response?.data || []).slice(0, 3).reverse());

        console.log("Latest Articles:", response?.data);
      } catch (error) {
        console.error("Error fetching latest articles:", error);
      }
    };

    fetchLatestArticles();
  }, []);

  return (
    <Box
      bg="white"
      py={10}
      px={{ base: 10, md: 40 }}
      style={{ fontFamily: "Avenir, sans-serif" }}
    >
      <Heading
        as="h2"
        size="2xl"
        mb={6}
        textAlign="left"
        color="#800020"
        style={{ fontFamily: "Avenir, sans-serif" }}
      >
        Read more
      </Heading>

      <SimpleGrid columns={{ lg: 3 }} gap={8}>
        {Array.isArray(latestArticles) &&
          latestArticles.map((article, index) => (
            <Box
              key={index}
              position="relative"
              // flexBasis={{ base: "100%", md: "30%" }}
              // width="min(100%,400px)"
              // width="min(100%,400px)"
              height="300px"
            >
              {article?.attributes?.HeaderImage?.data?.attributes?.url && (
                <Image
                  src={article.attributes.HeaderImage.data.attributes.url}
                  alt={`Article ${index + 1}`}
                  width="100%"
                  height="100%"
                  // height="290px"
                  // maxH="290px"
                  objectFit="cover"
                  borderRadius="md"
                  loading="lazy"
                />
              )}
              <Box
                position="absolute"
                bottom={0}
                // top="76%"
                // left="50%"
                // transform="translate(-50%, -50%)"
                w="100%"
                // textAlign="center"
              >
                <Box position="relative">
                  <Image
                    src={Articlecover}
                    alt="Overlay"
                    width="100%"
                    height={"200px"}
                    objectFit="cover"
                    objectPosition="top"
                  />

                  <Box
                    position="absolute"
                    bottom={"0"}
                    // bottom="50%"
                    // transform="translate(0%, -50%)"
                    px={4}
                    pl={"25%"}
                    py={8}
                  >
                    {article?.attributes?.Title && (
                      <Text color="white" fontSize="xl">
                        {article.attributes.Title}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* <Box
                  position="absolute"
                  bottom="12%"
                  // top="78%"
                  // left="50%"
                  // transform="translate(-50%, -50%)"
                  w="100%"
                  pl={20}
                  // pr={2}
                  textAlign="left"
                >
                  {article?.attributes?.Title && (
                    <Text color="white" fontSize="xl">
                      {article.attributes.Title}
                    </Text>
                  )}
                </Box> */}

              <Link
                as={RouterLink}
                to={`/blog/${article.attributes.Slug}`}
                position="absolute"
                top="50%"
                right="0"
                transform="translate(50%,-50%)"
              >
                <IconButton
                  aria-label="Go to Blog"
                  icon={<FiChevronRight size={24} color="#800020" />}
                  bg="#F2E5E9"
                  // _hover={{ bg: "transparent" }}
                  width="40%"
                />
              </Link>
            </Box>
          ))}
      </SimpleGrid>
    </Box>
  );
};

export default ArticlesSection;
