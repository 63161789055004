import React from 'react';
// import Home from '../Components/home/Home';
import Home from '../Components/home/Hheader';
import Gains from '../Components/gains/Gains';
import Support from '../Components/support/Support';
import Companies from '../Components/companies/Companies';
import Articles from '../Components/articles/Articles';

export default function Homefull() {
  return (
    <div>
      <Home />
      <Gains />
      <Support />
      <Companies />
      <Articles />
    </div>
  );
}
