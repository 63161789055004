import { Box, Image } from "@chakra-ui/react";
// import bottomImage from "../../images/pinkUnion.png";

export function Hero({ backgroundImage, bottomImage, children }) {
  return (
    <Box position="relative">
      <Box
        position="relative"
        //   height={{ base: "600px", md: "400px", lg: "1000px" }}
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        color="white"
        textAlign={{ base: "center", md: "left" }}
      >
        {children}
      </Box>
      <Image
        src={bottomImage}
        alt="Bottom Image"
        w="100%"
        objectFit="cover"
        position="absolute"
        bottom="0"
        left="0"
      />
    </Box>
  );
}
