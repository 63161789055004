import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { getEventsOnDate } from "../../service/eventsapi";
import calendarBackgroundImage from "../../images/eventcircles.png";

const CustomCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [eventsOnDate, setEventsOnDate] = useState([]);

  const fetchEventsOnDate = async () => {
    const formattedDate = selectedDate.toISOString().split("T")[0];
    console.log("Formatted Date:", formattedDate);
    try {
      const eventsResponse = await getEventsOnDate(formattedDate);
      const events = eventsResponse.data;
      console.log("Fetched events:", events);
      setEventsOnDate(events);
    } catch (error) {
      console.error("Error fetching events on date:", error);
    }
  };

  useEffect(() => {
    fetchEventsOnDate();
  }, [selectedDate]);

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay();
  };

  const generateCalendar = () => {
    console.log("Selected Date:", selectedDate);
    const daysInMonth = getDaysInMonth(
      selectedDate.getMonth(),
      selectedDate.getFullYear()
    );
    const firstDayOfMonth = getFirstDayOfMonth(
      selectedDate.getMonth(),
      selectedDate.getFullYear()
    );
    const days = Array.from({ length: daysInMonth }, (_, index) => index + 1);

    return (
      <Container
        maxW="container.sm"
        textAlign="center"
        p={0}
        m={0}
        style={{ fontFamily: "Avenir, sans-serif" }}
      >
        <Box overflowX="auto">
          <Table
            variant="unstyled"
            colorScheme="#902124"
            size={["sm", "md", "lg"]}
            borderWidth="2px"
            fontSize={["xs", "sm", "md"]}
          >
            <Thead>
              <Tr>
                {["S", "M", "T", "W", "TH", "F", "S"].map((day) => (
                  <Th
                    key={day}
                    textAlign="center"
                    color="black"
                    fontSize={["xs", "sm", "md"]}
                    bgColor="white"
                  >
                    {day}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {Array.from(
                { length: Math.ceil((daysInMonth + firstDayOfMonth) / 7) },
                (_, weekIndex) => (
                  <Tr key={weekIndex}>
                    {Array.from({ length: 7 }, (_, dayIndex) => {
                      const dayNumber =
                        weekIndex * 7 + dayIndex + 1 - firstDayOfMonth;

                      return (
                        <Td
                          key={dayIndex}
                          textAlign="center"
                          bgColor={
                            dayNumber === selectedDate.getDate()
                              ? "#F2E5E9"
                              : "white"
                          }
                          color={
                            dayNumber === selectedDate.getDate()
                              ? "#333232"
                              : "#333232"
                          }
                          border="2px"
                          borderColor="#333232"
                          onClick={() => handleDateClick(dayNumber)}
                          cursor="pointer"
                          // p={['2px', '4px', '20px']}
                          fontSize={["xs", "sm", "md"]}
                          style={{ fontFamily: "Avenir, sans-serif" }}
                        >
                          {dayNumber > 0 && dayNumber <= daysInMonth
                            ? dayNumber
                            : ""}
                          {eventsOnDate &&
                            Array.isArray(eventsOnDate) &&
                            eventsOnDate
                              .filter((event) => {
                                const eventDate = new Date(
                                  event.attributes.StartDate
                                );
                                return (
                                  eventDate.getMonth() ===
                                    selectedDate.getMonth() &&
                                  eventDate.getFullYear() ===
                                    selectedDate.getFullYear()
                                );
                              })
                              .map((filteredEvent) => (
                                <div key={filteredEvent.id}>
                                  {new Date(
                                    filteredEvent.attributes.StartDate
                                  ).getDate() === dayNumber && (
                                    <p
                                      style={{
                                        backgroundColor: "#99334D",
                                        color: "white",
                                        padding: "3px",
                                        borderRadius: "5px",
                                        fontFamily: "Avenir, sans-serif",
                                      }}
                                    >
                                      {filteredEvent.attributes.Name}
                                    </p>
                                  )}
                                </div>
                              ))}
                        </Td>
                      );
                    })}
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </Box>
      </Container>
    );
  };

  const handleDateClick = (day) => {
    console.log("Clicked Day:", day);
    setSelectedDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        day,
        0,
        0,
        0,
        0
      )
    );
  };

  const handlePrevMonth = () => {
    setSelectedDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() - 1,
        selectedDate.getDate()
      )
    );
  };

  const handleNextMonth = () => {
    setSelectedDate(
      new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        selectedDate.getDate()
      )
    );
  };

  return (
    <Box
      bgImage={`url(${calendarBackgroundImage})`}
      bgSize="cover"
      bgPosition="center"
      color="white"
      paddingY={20}
      paddingX={5}
      borderRadius="md"
      style={{ fontFamily: "Avenir, sans-serif" }}
    >
      <Container
        maxW="container.sm"
        pt={5}
        mb={5}
        color="black"
        borderRadius="md"
        style={{ fontFamily: "Avenir, sans-serif" }}
      >
        <Flex
          align="center"
          justify="space-between"
          pb={2}
          bg="rgba(144, 33, 36, 0)"
        >
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={handlePrevMonth}
            width="30px"
            ml={{ base: 0, md: 40 }}
          />
          <Heading
            as="h2"
            size="xl"
            textAlign="center"
            mb={2}
            style={{ fontFamily: "Avenir, sans-serif" }}
          >
            {selectedDate.toLocaleString("default", { month: "long" })}
          </Heading>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={handleNextMonth}
            width="30px"
            mr={{ base: 0, md: 40 }}
          />
        </Flex>
        <Text textAlign="center" fontSize="md" pb={2} bg="rgba(144, 33, 36, 0)">
          {selectedDate.getFullYear()}
        </Text>
        <Flex justify="center" mb={2}>
          {generateCalendar()}
        </Flex>
      </Container>
    </Box>
  );
};

export default CustomCalendar;
