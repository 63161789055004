import { baseAPI } from "./api";

export const getAllBlogPosts = async (filters) => {
  try {
    const response = await baseAPI.get(
      // `/blogs?fields[0]=Title&fields[1]=Slug&fields[2]=PublishedDate&fields[3]=createdAt&fields[4]=updatedAt&fields[5]=publishedAt&fields[6]=AuthorsName&fields[7]=BlogCategory&populate[HeaderImage][fields][1]=url`,
      `/blogs?populate[HeaderImage][fields][1]=url&sort[0]=PublishedDate:desc${
        filters ? `&${filters}` : ""
      }`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error getting blog posts:", error);
    throw error;
  }
};

export const getBlogPostByTitle = async (title) => {
  try {
    const response = await baseAPI.get(
      `/blogs?Title=${encodeURIComponent(title)}&_limit=1&populate=*`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error getting blog post:", error);
    throw error;
  }
};
export const getBlogPostBySlug = async (slug) => {
  try {
    const response = await baseAPI.get(
      `/blogs?filters[Slug][$eq]=${slug}&populate=*`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error getting blog post:", error);
    throw error;
  }
};
