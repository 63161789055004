import React from 'react';
import Navbar from '../Components/contact/Cheader';
import Contactus from '../Components/contact/ContactUs';

export default function Homefull() {
  return (
    <div>
      <Navbar />
      <Contactus />
    </div>
  );
}