import React from 'react';
import Bheader from '../Components/blogs/Bheader';
import Blog from '../Components/blogs/Blog';

export default function Homefull() {
  return (
    <div>
      <Bheader />
      <Blog />
    </div>
  );
}