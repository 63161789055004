import React from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  Link,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import backgroundImage from "../../images/homeheader.png";
import bottomImage from "../../images/pinkUnion.png";
import Logo from "../../images/clearlogo.png";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FaBars } from "react-icons/fa";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Box position="relative" style={{ fontFamily: "Avenir, sans-serif" }}>
      {/* <Box
        position="relative"
        // height={{ base: "600px", md: "400px", lg: "1000px" }}
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        color="white"
        textAlign={{ base: "center", md: "left" }}
      > */}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        bg="#00000000"
        py={10}
        px={{ base: 10, md: 40 }}
        color="white"
        position="absolute"
        width="100%"
        zIndex="1"
      >
        <Image
          src={Logo}
          alt="Innovate Africa Fund Logo"
          boxSize={{ base: "50%", md: "15%" }}
          objectFit="contain"
        />

        {isLargerThan768 ? (
          <Flex>
            <Link href="/" mr={4} fontSize={25}>
              Home
            </Link>
            <Link href="/about" mr={4} fontSize={25}>
              About Us
            </Link>
            <Link href="/events" mr={4} fontSize={25}>
              Events
            </Link>
            <Link href="/blog" mr={4} fontSize={25}>
              Blog
            </Link>
            <Link href="/contact" fontSize={25}>
              Contact Us
            </Link>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfe4Alt9dfLG04adRo5S_otP5PEMkLUObFL3fy3Xn6ZUkQoQA/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                bg="#FFFFFF"
                color="#800020"
                p={5}
                width="100px"
                rightIcon={<ChevronRightIcon />}
                borderRadius={0}
                ml={4}
              >
                Apply
              </Button>
            </a>
          </Flex>
        ) : (
          <Button
            onClick={onOpen}
            color="#FEF7F9"
            width="40px"
            padding={0}
            borderRadius="md"
            bg="rgba(144, 33, 36, 0.1)"
          >
            <FaBars size={24} style={{ color: "#FEF7F9" }} />
          </Button>
        )}

        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              <Flex direction="column">
                <a href="/" onClick={onClose}>
                  Home
                </a>
                <a href="/about" onClick={onClose}>
                  About Us
                </a>
                <a href="/events" onClick={onClose}>
                  Events
                </a>
                <a href="/blog" onClick={onClose}>
                  Blog
                </a>
                <a href="/contact" onClick={onClose}>
                  Contact Us
                </a>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfe4Alt9dfLG04adRo5S_otP5PEMkLUObFL3fy3Xn6ZUkQoQA/viewform?usp=sf_link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    bg="#800020"
                    color="#FFFFFF"
                    p={5}
                    width="100px"
                    rightIcon={<ChevronRightIcon />}
                    borderRadius={0}
                  >
                    Apply
                  </Button>
                </a>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
      <Box
        position="absolute"
        top="50%"
        left={{ base: "0", md: "40px", lg: "35%" }}
        transform="translateY(-50%)"
        zIndex="1"
        padding={{ base: "20px", md: "40px", lg: "60px" }}
      >
        <Text
          fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
          fontWeight="bold"
          mb={2}
        >
          Are you ann early-stage startup that wants to provide
          technology-driven solutions to solve unique African problems? Do you
          need funds, resources and support to get your product to the market in
          record time?
        </Text>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfe4Alt9dfLG04adRo5S_otP5PEMkLUObFL3fy3Xn6ZUkQoQA/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            bg="rgba(144, 33, 36, 0.1)"
            color="white"
            p={6}
            width="150px"
            mt={5}
            rightIcon={<ChevronRightIcon />}
          >
            Apply Here
          </Button>
        </a>
      </Box>

      <Image
        src={bottomImage}
        alt="Bottom Image"
        w="100%"
        objectFit="cover"
        position="absolute"
        bottom="0"
        left="0"
      />
    </Box>
  );
};

export default Header;
