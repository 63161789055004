import { baseAPI } from "./api";

export const createEvent = async (eventData) => {
  try {
    const response = await baseAPI.post(`/event-calendars`, eventData, {});
    return response.data;
  } catch (error) {
    console.error("Error creating event:", error);
    throw error;
  }
};

export const getAllEvents = async () => {
  try {
    const response = await baseAPI.get(`/event-calendars`, {});
    return response.data;
  } catch (error) {
    console.error("Error getting events:", error);
    throw error;
  }
};

export const getEventsOnDate = async (date) => {
  try {
    const response = await baseAPI.get(
      `/event-calendars?StartDate=${date}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error getting events on date:", error);
    throw error;
  }
};
