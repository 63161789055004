import axios from "axios";

const BASE_URL = "https://innovate-africa-fund-2357b25cc118.herokuapp.com/api";

export const baseAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization:
      "Bearer 85d80a85cdfe70400b36c93fb138af9c215541abda2e51286cae9e1c89a362eae18e55441e2ac67a5cab9cce99339ee4d21b56993d3c34be448d124dfdb2eca1660fa887424e86e3cc70f8f3a8be2e8e46b6aafd4a9dba712bbd46c4060204eeed7c3faa5268672d427187278e7e9fbfd7f979f3cb2bc29c2aed4d93c0d07645",
  },
});
