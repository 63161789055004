import React from 'react';
import Aheader from '../Components/about/Aheader';
import About from '../Components/about/About';

export default function Homefull() {
  return (
    <div>
      <Aheader />
      <About />
    </div>
  );
}