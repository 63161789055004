import React from "react";
import Bheader from "../Components/blogs/Bheader";
import FullArticle from "../Components/blogs/FullArticle";
import More from "../Components/blogs/More";

export default function Homefull() {
  return (
    <div>
      <Bheader />
      <FullArticle />
      <More />
    </div>
  );
}
