import React, { useEffect, useState } from "react";
import Header from "../../Components/header/Header";
import { Hero } from "../../Components/hero/Hero";
import { Box, Text } from "@chakra-ui/react";
import bottomImage from "../../images/Union.png";
import backgroundImage from "../../images/homeheader.png";
import mdk from "./privacy.md";
import ReactMarkdown from "react-markdown";
import { Prose } from "@nikolovlazar/chakra-ui-prose";

export default function Privacy() {
  const [post, setPost] = useState("");

  useEffect(() => {
    fetch(mdk)
      .then((res) => res.text())
      .then((res) => setPost(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Header />
      <Hero backgroundImage={backgroundImage} bottomImage={bottomImage}>
        <Box height={{ base: "500px", md: "300px", lg: "600px" }}>
          <Box
            position="absolute"
            top="50%"
            left={{ base: "0", md: "40px", lg: "35%" }}
            transform="translateY(-50%)"
            zIndex="1"
            padding={{ base: "20px", md: "40px", lg: "60px" }}
          >
            <Text
              fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
              fontWeight="bold"
              mb={2}
            >
              Privacy Policy
            </Text>
          </Box>
        </Box>
      </Hero>

      <Box>
        <Prose maxWidth="80ch" mx={"auto"}>
          <ReactMarkdown>{post}</ReactMarkdown>
        </Prose>
      </Box>
    </div>
  );
}
